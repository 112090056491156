#start-header {
  width: 100%;
  height: 70vh;
  background-image: url("../start-background.72b800e6.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

#start-header-message {
  width: 300px;
  color: #fff;
  border-radius: 10%;
  margin: 0 10%;
  padding: 20px;
}

#start-header-message:before {
  border-radius: 3px;
}

#start-header-message > p {
  margin: 0;
  font-size: 20px;
}

#success-message-container, #error-message-container {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}

.app-container {
  background-color: #efefef;
  margin: 0;
  font-family: Segoe UI;
}

.app-description {
  font-size: 20px;
}

.links-container {
  min-height: 70px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

.google-play-badge {
  height: 70px;
}

ms-store-badge {
  height: 48px;
  margin: 0 8px;
}

ms-store-badge::part(img) {
  height: 48px;
}

.screenshot-storyline, .screenshot-pocketlib, .screenshot-calendo {
  min-width: 250px;
  max-width: 400px;
}

.screenshot-universalsoundboard {
  min-width: 300px;
  max-width: 700px;
}

/*# sourceMappingURL=start-page.cd5fc5cb.css.map */
