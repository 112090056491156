#start-header {
	background-image: url("/assets/images/start-background.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	width: 100%;
	height: 70vh;
}

#start-header-message {
	padding: 20px;
	width: 300px;
	margin: 0 10%;
	color: white;
	border-radius: 10%;
}

#start-header-message::before {
	border-radius: 3px;
}

#start-header-message > p {
	margin: 0;
	font-size: 20px;
}

#success-message-container,
#error-message-container {
	width: 300px;
	position: absolute;
	right: 0;
	left: 0;
	margin-right: auto;
	margin-left: auto;
	top: 100px;
}

.app-container {
	margin: 0;
	background-color: #efefef;
	font-family: "Segoe UI";
}

.app-description {
	font-size: 20px;
}

.links-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	min-height: 70px;
	margin-top: 1.5rem;
}

.google-play-badge {
	height: 70px;
}

ms-store-badge {
	height: 48px;
	margin: 0 8px;
}

ms-store-badge::part(img) {
	height: 48px;
}

.screenshot-storyline,
.screenshot-pocketlib,
.screenshot-calendo {
	min-width: 250px;
	max-width: 400px;
}

.screenshot-universalsoundboard {
	min-width: 300px;
	max-width: 700px;
}
